import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useNavigateWithLang, useWebDispatch } from './store/hook';
// import AuthRoute from './router/AuthRouter';
import { changeRouterName } from "./store/app/slice"
import "./config/i18n"
import Loading from './components/Loading'


import router from './router'
import storage from './util/storage'
import { message } from 'antd'
import { GetConfigData } from './store/app/thunk'
import { UserProfile } from './store/user/thunk';
import { setUserInfo } from './store/user/slice';
import Layout from './Layout';
import { setLanguageId, setLang as setStoreLang, setToken } from './store/app/slice'
import { changeLanguage } from "./config/i18n"
import { LANG_VALUE } from './common/enum';

type LanguageList = {
  id: string,
  languageCode: string,
  name: string
  sequence: number
}[]

function App() {
  const location = useLocation();
  const dispatch = useWebDispatch();
  const { navigateWithLang } = useNavigateWithLang();

  const langId = localStorage.getItem('languageId')
  const storeLang = localStorage.getItem('lang')

  const defaultLanguage = 'zh-hk';

  const [loading, setLoading] = useState(false);

  // 获取config
  const getConfigData = async () => {
    let { payload } = await dispatch(GetConfigData())
    if (!payload.isSuccess) {
      message.error(payload.errorMessage)
    } else {
      return payload.data
    }
  }
  // 获取用户信息
  const getUserInfo = async () => {
    if (localStorage.getItem("accessToken")) {
      let user = await dispatch(UserProfile())
      if (user.payload.isSuccess) {
        dispatch(setUserInfo(user.payload.data))
        storage.setNotString('userInfo', user.payload.data)
      }
    }
  }

  const insertLangIdIfNotExist = (curLng: string, langList: LanguageList) => {
    // const isCompleteLang = curLng === 'zh-cn' || curLng === 'zh-hk'
    const value = curLng === 'zh-cn' ? 'sc' : 'tc'

    if (!langId || (value !== storeLang)) {
      let id = ''
      dispatch(setStoreLang(value))
      console.log('lang list: ', langList)
      langList.forEach((item: any) => {
        if (item.languageCode === value) {
          id = item.id
        }
      })

      if (id !== '') {
        dispatch(setLanguageId(id))
        localStorage.setItem('languageId', id)
        changeLanguage(value as LANG_VALUE)
        // window.location.reload()
      }
    }
    return
  }

  const init = async () => {
    const data = await getConfigData()
    await getUserInfo();

    if (data?.languageList?.length > 0) {
      const [, langCode] = location.pathname.split("/")
      insertLangIdIfNotExist(langCode, data.languageList)
    }
  }
  // 路由拦截
  const routerBeforeEach = () => {
    window.scrollTo(0, 0)
    console.log("当前页面=====>", location.pathname, location)
    dispatch(changeRouterName(location.pathname))
  }

  useEffect(() => {
    // if (location.pathname === '/') navigate('/home')
    const [, langCode, path] = location.pathname.split("/")
    const isIncompleteLang =
      langCode === 'zh-' ||
      langCode === 'zh-h' ||
      langCode === 'zh-c'

    const languageRegex = /^\/([a-z]{2}-[a-z]{2})(\/?.*)?$/;
    const match = location.pathname.match(languageRegex);

    if (!match) {
      navigateWithLang({
        path: path ? `/${path}` : '',
        lng: defaultLanguage
      })
    }

    routerBeforeEach()
  }, [location.pathname])

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      {/* <MyHeader /> */}
      <Routes>
        <Route path="/:lang" element={<Layout />}>
          {router.map((item, i: number) => (
            <Route
              key={`${item.path}-${i}`}
              path={`${item.path}`}
              element={
                <Suspense fallback={<Loading />}>
                  {/* <AuthRoute> */}
                  <item.component></item.component>
                  {/* </AuthRoute> */}
                </Suspense>
              }
            />
          ))}
          <Route path="*" element={<></>} />
        </Route>
      </Routes>
      {/* <MyFooter /> */}
    </>
  );
}

export default App;
