import { useState, useEffect } from "react"
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useWebSelector, useWebDispatch, useNavigateWithLang } from '../store/hook'
import { RootState } from "../store/store"
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Space } from 'antd'
import { setLanguageId, setLang as setStoreLang, setToken } from '../store/app/slice'
import { changeLanguage } from "../config/i18n"
import { useTranslation } from 'react-i18next'
import { genPathLangCode } from '../util/function'


const MyHeader = () => {

    /* hook */
    const { navigateWithLang, navigateWithLangAndParams } = useNavigateWithLang()
    const { pathname } = useLocation()
    const { lang: lng } = useParams()
    const [searchParams] = useSearchParams();
    const dispatch = useWebDispatch()
    const { t, i18n } = useTranslation()

    /* 变量 */
    const hasMobileViewParams = searchParams.get('view') === 'mobile'

    // 路由
    const navbar = [
        { lebal: t('tabbar.home'), path: 'home', fullPath: '/home' },
        { lebal: t('tabbar.about'), path: 'about', fullPath: '/about' },
        { lebal: t('tabbar.organization'), path: 'organization', fullPath: '/organization' },
        { lebal: t('tabbar.discount'), path: 'discounts', fullPath: '/discounts' },
        { lebal: t('tabbar.new'), path: 'news', fullPath: '/news' },
        { lebal: t('tabbar.hospital'), path: 'hospital', fullPath: '/hospital' },
        { lebal: t('tabbar.doctor'), path: 'doctor', fullPath: '/doctor' }
    ]
    // 记录当前navbar的index
    const [navIndex, setNavIndex] = useState(0)
    // 获取记录在store中的路由名称
    const { routerName } = useWebSelector((state: RootState) => state.app)
    // 语言
    // config中的语言列表
    const { accessToken, languageList, lang } = useWebSelector((state: RootState) => state.app)
    const [localToken] = useState(localStorage.getItem('accessToken'))
    const userMenu = [{ key: 'loginOut', label: t('logout') }]
    const { userInfo } = useWebSelector((state: RootState) => state.user)
    const [isCollapsed, setIsCollapsed] = useState(false)

    // const lng = genPathLangCode(lang)

    /* 方法 */
    const navigator = (path: string, index: number) => {
        setNavIndex(index)
        navigateWithLang({ path })
    }
    // 语言下拉菜单
    const langSelect = async (value: any) => {
        let id = ''
        const langPrefix = `/${lng}`
        const localPathname = pathname.startsWith(langPrefix)
            ? pathname.slice(langPrefix.length)
            : pathname

        dispatch(setStoreLang(value))
        languageList.forEach((item: any) => {
            if (item.key === value) {
                id = item.id
            }
        })
        dispatch(setLanguageId(id))
        localStorage.setItem('languageId', id)
        changeLanguage(value)

        const pathLng = genPathLangCode(value)
        // navigate to current path with new language
        navigateWithLangAndParams({
            path: localPathname,
            lng: pathLng,
            params: searchParams
        })
        // window.location.reload()
    }

    // 用户下拉菜单
    const userMenuSelect = (value: any) => {
        if (value === 'loginOut') {
            localStorage.removeItem('accessToken')
            dispatch(setToken(''))
        } else {
            navigateWithLang({ path: value })
        }
    }


    /* 监听 */
    useEffect(() => {
        navbar.forEach((item, index) => {
            if (routerName.includes(item.fullPath)) {
                setNavIndex(index)
            }
        })
        setIsCollapsed(true)
    }, [routerName])

    return (
        <div className="container-fluid sticky-top bg-white shadow-sm">
            <div className="container">
                <nav
                    className={`navbar navbar-expand-lg navbar-light bg-white py-lg-0 ${!hasMobileViewParams ? 'py-3 ' : 'py-0'}`}
                >
                    {!hasMobileViewParams && (
                        <a href={`/${lng}`} className="navbar-brand">
                            <img src="../assets/img/logo.svg" height="50" alt="" />
                        </a>
                    )}
                    <button
                        className={isCollapsed ? 'navbar-toggler collapsed' : 'navbar-toggler'}
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                        style={hasMobileViewParams
                            ? {
                                position: 'absolute',
                                borderRadius: 8,
                                right: 8,
                                top: 12,
                                backgroundColor: "#FFF",
                                zIndex: 2000
                            }
                            : {}
                        }
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={isCollapsed ? "collapse navbar-collapse" : "collapse navbar-collapse show"} id="navbarCollapse">
                        <div className="navbar-nav ms-auto py-0">
                            {navbar.map((item, index) => {
                                return (
                                    <a className={navIndex === index ? 'nav-item nav-link active' : 'nav-item nav-link'} key={index} onClick={() => navigator(item.fullPath, index)}>{item.lebal}</a>
                                )
                            })
                            }
                            {!hasMobileViewParams && (
                                <Dropdown menu={{ items: languageList, onClick: (e) => { langSelect(e.key) } }}>
                                    <a className="nav-item nav-link">
                                        <Space>
                                            {lang === 'sc' ? '简体中文' : '繁體中文'}
                                            <DownOutlined />
                                        </Space>
                                    </a>
                                </Dropdown>
                            )}
                        </div>
                        <div style={{ marginLeft: '50px', textAlign: 'right' }}>
                            {accessToken && <Dropdown menu={{ items: userMenu, onClick: (e) => { userMenuSelect(e.key) } }}>
                                <a className="nav-item nav-link">
                                    <Space>
                                        <div>{userInfo.lastName ? userInfo.lastName + '  ' + userInfo.firstName : t('to_login')}</div>
                                    </Space>
                                </a>
                            </Dropdown>}
                            {!accessToken && <div onClick={() => {
                                setIsCollapsed(true)
                                navigateWithLang({ path: '/login' })
                            }}>{t('to_login')}</div>}
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default MyHeader

