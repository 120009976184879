import { Button, Modal } from "antd"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useNavigateWithLang, useWebSelector } from "../store/hook"
import { RootState } from "../store/store"
import { useTranslation } from 'react-i18next'



const MyFooter = () => {

    /* hook */
    const navigate = useNavigate()
    const { navigateWithLang } = useNavigateWithLang();

    const { disclaimer, accessToken } = useWebSelector((state: RootState) => state.app)
    const { t } = useTranslation()

    /* 数据 */
    const [open, setOpen] = useState(false)

    /* 方法 */
    const go = (path: string) => {
        navigateWithLang({ path })
    }

    useEffect(() => {
        console.log(434, accessToken)
    }, [])

    return (
        <>
            <footer style={{
                color: '#fff',
                backgroundImage: "url('https://img.freepik.com/premium-photo/abstract-gradient-background-background-design_509471-38.jpg')",
                backgroundSize: "100% 100%"
            }}>
                <div className="container-fluid  text-light  ">
                    <div className="container py-5">
                        <div className="row g-5">
                            <div className="col-lg-4 col-md-6">
                                <div className="d-flex flex-column justify-content-start" style={{ marginTop: '60px' }}>
                                    <a className="text-light mb-2" onClick={() => go('/home')}><i className="fa fa-angle-right me-2"></i>{t('tabbar.home')}</a>
                                    <a className="text-light mb-2" onClick={() => go('/about')}><i className="fa fa-angle-right me-2"></i>{t('tabbar.about')}</a>
                                    <a className="text-light mb-2" onClick={() => go('/organization')}><i className="fa fa-angle-right me-2"></i>{t('tabbar.organization')}</a>
                                </div>

                                {/* <h4
                                    className="d-inline-block text-white text-uppercase border-bottom border-5 border-secondary mb-4">
                                    隨時聯繫我們</h4>
                                <p className="mb-2"><i className="fa fa-map-marker-alt text-primary me-3"></i>香港銅鑼灣白沙道16號1樓</p>
                                <p className="mb-0"><i className="fa fa-phone-alt text-primary me-3"></i>+852 2832 9922</p> */}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <h4
                                    className="d-inline-block text-white text-uppercase border-bottom border-5 border-secondary mb-4">
                                    {t('navigation')}</h4>
                                <div className="d-flex flex-column justify-content-start">
                                    <a className="text-light mb-2" onClick={() => go('/discounts')}><i className="fa fa-angle-right me-2"></i>{t('tabbar.discount')}</a>
                                    <a className="text-light mb-2" onClick={() => go('/news')}><i className="fa fa-angle-right me-2"></i>{t('tabbar.new')}</a>
                                    <a className="text-light mb-2" onClick={() => go('/hospital')}><i className="fa fa-angle-right me-2"></i>{t('tabbar.hospital')}</a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                {/* <h4
                                    className="d-inline-block text-white text-uppercase border-bottom border-5 border-secondary mb-4">
                                    訂閱我們</h4>
                                <div className="d-flex">
                                    <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2"><i
                                        className="fab fa-facebook-f"></i></a>
                                </div> */}
                                <div className="d-flex flex-column justify-content-start" style={{ marginTop: '60px' }}>
                                    <a className="text-light mb-2" onClick={() => go('/doctor')}><i className="fa fa-angle-right me-2"></i>{t('tabbar.doctor')}</a>
                                    {!accessToken && <a className="text-light mb-2" onClick={() => go('/login')}><i className="fa fa-angle-right me-2"></i>{t('tabbar.login')}</a>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid  text-light border-top border-secondary py-4">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-md-6 text-center text-md-start">
                                <p className="mb-md-0">&copy; <a className="text-white">YYT Technology Hub Limited</a>. All Rights Reserved.<span className="hover" style={{ marginLeft: '20px', fontSize: '14px' }} onClick={() => setOpen(true)}>{t('disclaimer')}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>


            <Modal title={disclaimer.name} open={open} onCancel={() => setOpen(false)} footer={[<Button type="primary" onClick={() => setOpen(false)}>{t('agree')}</Button>]}>
                <div style={{ height: "70vh", overflow: "auto", whiteSpace: "pre-wrap" }}>
                    {disclaimer.description}
                </div>
            </Modal>
        </>
    )
}

export default MyFooter