import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

import MyHeader from './components/MyHeader';
import MyFooter from './components/MyFooter';
import { Helmet } from 'react-helmet';

const Layout = () => {
    const { lang } = useParams();

    // Load translations or handle language-specific logic here

    return (
        <div>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            </Helmet>
            <header>
            </header>
            <main>
                <MyHeader />
                <Outlet />
                <MyFooter />
            </main>
            <footer></footer>
        </div>
    );
};

export default Layout;