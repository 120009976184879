

import { lazy } from "react"



const router = [
    {
        path: "",
        component: lazy(() => import("../pages/Index"))//首页
    },
    {
        path: "home",
        component: lazy(() => import("../pages/Index"))//首页
    },
    {
        path: "about",
        component: lazy(() => import("../pages/About/Index"))//关于
    },
    {
        path: "organization",
        component: lazy(() => import("../pages/Organization/Index"))//药械通
    },
    {
        path: 'discounts',
        component: lazy(() => import("../pages/Discounts/Index"))//优惠
    },
    {
        path: 'discountsDetail',
        component: lazy(() => import("../pages/Discounts/Detail"))//优惠详情
    },
    {
        path: 'news',
        component: lazy(() => import("../pages/News/Index"))//新闻
    },
    {
        path: 'newsDetail',
        component: lazy(() => import("../pages/News/Detail"))//新闻详情
    },
    {
        path: 'hospital',
        component: lazy(() => import("../pages/Hospital/Index"))//医院
    },
    {
        path: 'hospitalDetail',
        component: lazy(() => import("../pages/Hospital/Detail"))//医院详情
    },
    {
        path: 'doctor',
        component: lazy(() => import("../pages/Doctor/Index"))//医生
    },
    {
        path: 'doctorDetail',
        component: lazy(() => import("../pages/Doctor/Detail"))//医生详情
    },
    {
        path: 'login',
        component: lazy(() => import("../pages/Login"))//登录
    }
]

export default router