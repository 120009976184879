import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { WebDispatch, RootState } from './store';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const useWebDispatch = () => useDispatch<WebDispatch>()
export const useWebSelector: TypedUseSelectorHook<RootState> = useSelector

export const useApplyTranslation = (lang: string, enStr: string, tcStr: string) => {
  if (lang == 'en') return enStr
  else if (lang == 'tc') return tcStr
}

export const useNavigateWithLang = () => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const hasMobileViewParams = searchParams.get('view')
  const mobileViewParams = hasMobileViewParams ? `?view=${hasMobileViewParams}` : ''

  const navigateWithLang = ({ path, lng }: { path: string, lng?: string }) => {
    return navigate(`/${lng || lang}${path}${mobileViewParams}`)
  }

  const navigateWithLangAndParams = ({ path, params, lng }: {
    path: string,
    params: { [key: string]: string } | URLSearchParams,
    lng?: string
  }) => {
    console.log('params', params)
    const mergedParams = {
      ...Object.fromEntries(searchParams),
      ...(typeof params === 'object' ? params : Object.fromEntries(params)),
    }

    const mobileSearchString = new URLSearchParams(mergedParams).toString()
    const searchString = new URLSearchParams(mergedParams)
      .toString()
      .replace(/view=mobile|view=mobile\?/gi, '');
    const queryParams = hasMobileViewParams
      ? `?${mobileSearchString}`
      : `?${searchString}`

    return navigate(`/${lng || lang}${path}${queryParams}`)
  }

  return { navigateWithLang, navigateWithLangAndParams }
}